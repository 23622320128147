body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
    pointer-events: none;
}

.awssld {
  --slider-height-percentage: 56%;
  --slider-transition-duration: 321ms;
  --organic-arrow-thickness: 2px;
  --organic-arrow-border-radius: 30px;
  --organic-arrow-height: 34px;
  --organic-arrow-color: #ffffff;
  --control-button-width: 5%;
  --control-button-height: 25%;
  --control-button-background: transparent;
  --control-bullet-color: #f46b34;
  --control-bullet-active-color: #cf5b2c;
  --loader-bar-color: #a2df0f;
  --loader-bar-height: 1px;
}